import { fetchData } from "../../../libs/Fetch";
import GeneralUtility from "../../../libs/GeneralUtility";
import ContentLibs from "../../cms_content/libs/ContentLibs";
import { getEpisodeConfig } from "./PodcastApiService";

// const additionalParams = 'type=1598940600';
const domain = process.env.NEXT_PUBLIC_ARTICLE_API_DOMAIN;
const apiBasePath = "/restarticle";

const fetch = (url, payload = {}, method = "GET") => {
  url = apiBasePath + url;
  // url += url.indexOf('?') > 0 ? '&' : '?';
  // url += additionalParams;

  return fetchData(domain + url, payload, method);
};

/**
 * Fetch articles
 *
 * @param filter
 * @param limit
 * @param start
 */
const fetchArticles = (filter, limit = 25, start = 0) => {
  // let url = `/articles?_page=${page}&_limit=${limit}`;
  let url = "/list?type=1598940600";

  let request = {
    sortings: [
      {
        field: filter?.sortings?.field ?? "sortdatum",
        direction: filter?.sortings?.direction ?? "DESC",
      },
    ],
    page: {
      start: parseInt(start),
      limit: parseInt(limit),
    },
  };

  if (!GeneralUtility.isEmptyObject(filter)) {
    // change rubrik filter key to api key
    if (filter.rubrik) {
      filter = {
        ...filter,
        categories: filter.rubrik,
      };
      delete filter.rubrik;
    }

    request = {
      ...request,
      ...filter,
    };
  }

  // return fetch(url);
  return fetch(url, request, "POST");
};

/**
 * Fetch articles
 *
 * @param slug
 * @param isPreview
 */
const fetchArticleBySlug = (slug, isPreview = false) => {
  let url =
    "/show/restarticle-article/" +
    encodeURIComponent(slug.toString().toLowerCase()) +
    "?type=1598940600";

  if (isPreview) {
    url += "&preview=1";
  }

  let request = {
    countSimilarArticles: 9,
    periodSimilarArticles: "P3Y",
    isPreview,
  };

  return fetch(url, request, "POST").then(async (resp) => resp.result);
};

/**
 * increase article read counter
 *
 * @param articleUid
 * @returns {Promise<*>}
 */
const increaseArticleViewCounter = (articleUid) => {
  let url = `/index.php?eID=ltoArtikelCounter&artikel=${articleUid}`;

  let request = {};

  return fetchData(domain + url, [], "GET", false);
};

const getImageUrl = (
  imageUid,
  config = {},
  idIsReference = true,
  domain = ""
) => {
  if (!imageUid) return null;

  if (!domain) {
    domain = process.env.NEXT_PUBLIC_ARTICLE_API_DOMAIN;
  }

  let imagePath = domain;

  if (idIsReference) {
    imagePath += `/restimage-reference/image/restimage-file/${imageUid}/restimage-file-config`;
  } else {
    imagePath += `/restimage/image/restimage-file/${imageUid}/restimage-file-config`;
  }

  let manipulationPath = "";

  ["width", "height", "maxWidth", "cropVariant"].map((argument) => {
    if (argument && config[argument]) {
      manipulationPath = ContentLibs.getImageManipulationSegment(
        manipulationPath,
        argument,
        config[argument]
      );
    }
  });

  if (manipulationPath) {
    imagePath += `/${manipulationPath}`;
  }

  return imagePath + "?type=1588659882";
};

const getImageCropVersions = (imageId, treatIdIsReference = true) => {
  const imagePathSmall = getImageUrl(
    imageId,
    { width: 450, cropVariant: "mobile" },
    treatIdIsReference
  );
  const imagePathMedium = getImageUrl(
    imageId,
    { width: 800, cropVariant: "tablet" },
    treatIdIsReference
  );
  const imagePathLarge = getImageUrl(
    imageId,
    { width: 1280, cropVariant: "desktop" },
    treatIdIsReference
  );
  return [imagePathSmall, imagePathMedium, imagePathLarge];
};

export default {
  fetchArticles,
  fetchArticleBySlug,
  getImageUrl,
  increaseArticleViewCounter,
  getImageCropVersions,
};
