import React from "react";
import Image from "../../../../../components/Image";
import ContentApiService from "../../../services/ContentApiService";
import OptionalLinkWrapper from "../../../../../components/OptionalLinkWrapper";

const ApiImage = ({
  src,
  id,
  image = {},
  treatIdIsReference = true,
  cropVariant = "",
  linkHref,
  linkAs,
  className = "",
  lazy = true,
  lazyClass = "lazy",
  apiDomain = "",
  description = "",
  nextOptimizedImage = false,
  width,
  height,
  layout,
  title,
  alt,
}) => {
  const imageClasses = [];
  let imagePath = "";
  let additionalAttributes = {};

  // fallback fpr old deprecated version
  if (id) {
    image = { uid: id };
  }

  if (alt) {
    additionalAttributes = { alt: alt, ...additionalAttributes };
  }

  if (title) {
    additionalAttributes = { title: title, ...additionalAttributes };
  }

  // use local filepath to reference image
  if (image?.uid) {
    const { alternative = "", title = "" } = image;
    additionalAttributes = { alt: alternative, title, ...additionalAttributes };
    imagePath = ContentApiService.getImageUrl(
      image.uid,
      { width, height, cropVariant },
      treatIdIsReference,
      apiDomain
    );
  } else {
    imagePath = src;
  }

  if (!imagePath) return null;

  if (lazy) {
    imageClasses.push(lazyClass);
  }

  if (!imagePath) return;

  if (className && imageClasses) {
    className += " ";
  }

  let nextOptimizedImageParams = {};
  if (nextOptimizedImage) {
    nextOptimizedImageParams = { width, height, layout };
  }

  return (
    <OptionalLinkWrapper href={linkHref}>
      <Image
        src={`${imagePath}`}
        lazy={lazy}
        className={`${className}${imageClasses.join(" ")}`}
        nextOptimizedImage={nextOptimizedImage}
        {...additionalAttributes}
        {...nextOptimizedImageParams}
      />
    </OptionalLinkWrapper>
  );
};

export default ApiImage;
