import styles from "../../employer/components/EmployerTransfers/ListItem/ListItem.module.scss";
import PagePathUtility from "../../../libs/PagePathUtility";

const getTitle = (author) => {
  if (author.titlePre) {
    return author.titlePre;
  }

  if (author.autor && author.autor.title) {
    return author.autor.title;
  }

  return "";
};
const getTitlePost = (author) => {
  if (author.titlePost) {
    return author.titlePost;
  }
  return "";
};

const getFirstName = (author) => {
  if (author.firstName) {
    return author.firstName;
  }

  if (author.autor) {
    return author.autor.firstName;
  }

  return "";
};

const getLastName = (author) => {
  if (author.lastName) {
    return author.lastName;
  }

  if (author.autor) {
    return author.autor.lastName;
  }

  return "";
};

const getFullName = (author) => {
  if (author.name) {
    return author.name;
  }

  return "";
};

const getImage = (author) => {
  if (author.image) {
    return author.image;
  }

  return "";
};

const getAuthorId = (author) => {
  if (author.autor && "uid" in author.autor && author.autor.uid) {
    return author.autor.uid;
  }

  return "";
};

const getDescription = (author) => {
  if (author.description && "description" in author && author) {
    return author.description;
  }

  return "";
};

const getAllAuthorsString = (authors, addLinkToAuthorPage= false) => {
  let authorsString = "";
  let authorString = '';

  for (let author of authors) {

    authorsString += authorsString.length > 0 ? " und " : "";

    authorString = '';

    if (getFullName(author)) {
      authorString += getFullName(author);
    } else {

      if (getTitle(author)) {
        authorString += `${getTitle(author)} `;
      }
      authorString += `${getFirstName(author)} ${getLastName(author)}`;

      if (getTitlePost(author)) {
        authorString += `, ${getTitlePost(author)}`;
      }
    }

    if (addLinkToAuthorPage && author.pathSegment != '') {

      const href = PagePathUtility.getPathConfig(
          "articleAuthor",
          author,
      );

      authorString = '<a href="' + href + '" target="_blank">' + authorString + '</a>';
    }

    authorsString += authorString;

  }
  return authorsString;
};

export default {
  getFirstName,
  getLastName,
  getImage,
  getAuthorId,
  getAllAuthorsString,
  getDescription,
};
