import getConfig from "next/config";

/**
 * Get page path config for current article
 *
 * @param article
 * @returns {string}
 */
export const getArticleUrlConfigKey = (article) => {
  const { publicRuntimeConfig } = getConfig();
  let urlConfigKey = "";

  const pagePathMapping = publicRuntimeConfig.articles.pagePathMapping;

  for (const linkConfigKey in pagePathMapping) {
    const linkFilterConfig = pagePathMapping[linkConfigKey];

    let allFilterMatched = true;
    for (const filterProperty in linkFilterConfig) {
      if (
        !compareValues(
          article[filterProperty],
          linkFilterConfig[filterProperty]
        )
      ) {
        allFilterMatched = false;
        break;
      }
    }

    if (allFilterMatched) {
      urlConfigKey = linkConfigKey;
      break;
    }
  }

  // return config key => fallback to first page path
  return urlConfigKey || Object.keys(pagePathMapping)[0];
};

const compareValues = (value1, value2) => {
  let match = false;
  if (Array.isArray(value1)) {
    // check if one item matches
    let subItemMatch = false;
    for (const item of value1) {
      if (compareValues(item, value2)) {
        subItemMatch = true;
        break;
      }
    }
    match = subItemMatch;
  } else if (value1 === value2) {
    match = true;
  } else if (value1 && "uid" in value1 && value1.uid === value2) {
    match = true;
  }
  return match;
};
